import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Icon from '../icon/Icon'
import AuthContext from '../../contexts/authContext'
import { TableLoader, showLoader } from '../../services/loader.services'
import NoDataMsg from '../../common/components/NoDataMsg'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { getScannedProductionEntryList, getScannedRibbonRollStock } from '../../services/stockService'
import { toasts } from '../../services/toaste.service'
import { deleteProductionEntryCount, getProductionEntryCount } from '../../services/orderService'
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal'
import { getLicenseKey } from '../../services/application.settings'
import AlertService from '../../services/alert.service'

const ProductionEntryScannedList = () => {

    useEffect(() => {
        getProductionEntryCountList()
    }, [])

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [scannedData, setScannedData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const { items, requestSort, getClassNamesFor } = useSortableData(scannedData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [dataStatus, setDataStatus] = useState(false)

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.machineName !== null && i.machineName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.labelSizeDescription !== null && i.labelSizeDescription.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.stockCount !== null && i.stockCount.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function getProductionEntryCountList() {
        getProductionEntryCount(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productionEntryCount;
                    if (data != undefined) {
                        setScannedData(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                    setScannedData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [productionEntryCountId, setProductionEntryCountId] = useState('')

    function productionEntryCountDetails() {
        return ({
            licenseKey: getLicenseKey,
            productionEntryCountId: productionEntryCountId,
            userAccountId: userAccountId,
        })
    }

    function deleteProductionEntrySubmit() {
        showLoader(true)
        let productionEntryCountPostData = productionEntryCountDetails()
        deleteProductionEntryCount(productionEntryCountPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    setProductionEntryCountId('')
                    setIsDeleteOpen(false)
                    getProductionEntryCountList()
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    return (
        <PageWrapper title={`Production Entry List`}>
            <Page>
                <Card stretch data-tour='list' className='d-print-none'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info'>
                            <CardTitle>
                                Production Entry List
                            </CardTitle>
                        </CardLabel>

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                        </ButtonGroup>
                    </CardHeader>
                    <CardBody isScrollable>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('machineName')} className='cursor-pointer text-decoration-underline'>Machine Name <Icon size='lg' className={getClassNamesFor('machineName')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('labelSizeDescription')} className='cursor-pointer text-decoration-underline'>Label Size Description<Icon size='lg' className={getClassNamesFor('labelSizeDescription')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('stockCount')} className='cursor-pointer text-decoration-underline'>Total Roll <Icon size='lg' className={getClassNamesFor('stockCount')} icon='FilterList' /></th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {scannedData != "" ? <>{filteredData.map((item: any) => (<tbody key={item.productionEntryCountId}><tr><td>{item.sno}</td><td>{item.machineName}</td><td>{item.labelSizeDescription}</td><td>{item.stockCount}</td><td className="d-print-none"><Button color='danger' isLight icon='Delete' aria-label='Delete' onClick={() => { setIsDeleteOpen(true); setProductionEntryCountId(item.productionEntryCountId) }}></Button></td></tr></tbody>))}</> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>

                <Modal
                    setIsOpen={setIsDeleteOpen}
                    isOpen={isDeleteOpen}
                    titleId='edit'
                    isStaticBackdrop
                    size='sm'>
                    <ModalHeader>{''}</ModalHeader>
                    <ModalBody>
                        <div className="row g-3">
                            <div className="col-12 d-flex justify-content-center">
                                <h5><strong>Are you sure want to delete ?</strong></h5>
                            </div>
                            <div className='col-10'>
                                <div className='d-flex gap-2 justify-content-end'>
                                    <Button color='danger' size={'sm'} onClick={deleteProductionEntrySubmit}>
                                        Yes
                                    </Button>

                                    <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        No
                                    </Button>
                                </div>
                            </div>
                            <div className='col-10'></div>
                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default ProductionEntryScannedList