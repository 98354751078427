import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Icon from '../icon/Icon'
import { getLicenseKey } from '../../services/application.settings'
import AuthContext from '../../contexts/authContext'
import { showLoader } from '../../services/loader.services'
import AlertService from '../../services/alert.service'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { toasts } from '../../services/toaste.service'
import { addOrderReceivedDetails, cancelOrderReceivedDetails, getOrderReceivedDetails, updateOrderReceivedQuantityDetails } from '../../services/orderService'
import { getCurrentDateAndTime, getSubModuleType } from '../../services/common.service'
import SearchableSelect from '../../common/components/SearchableSelect'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal'
import NoDataMsg from '../../common/components/NoDataMsg'
import useSortableData from '../../hooks/useSortableData'
import InputGroup from '../bootstrap/forms/InputGroup'
import Select from '../bootstrap/forms/Select'
import Popovers from '../bootstrap/Popovers'

const OrderReceivedForm = () => {

    useEffect(() => {
        getOrderReceivedDetailsList()
        getCustomerDetailsList()
    }, [])

    const orderReceivedForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            customerName: '',
            poNumber: '',
            orderDate: getCurrentDateAndTime('date'),
            email: '',
            phoneNumber: '',
            formAddedBy: ''
        },
        validate: (values: any) => {
            const errors: {
                customerName?: string;
                poNumber?: string;
                orderDate?: string;
                email?: string;
                phoneNumber?: string;
                formAddedBy?: string;
            } = {};
            if (isVisibleOnCustomerName == true && !customerName?.value) {
                errors.customerName = 'Required';
            }
            if (isVisibleOnCustomerName == false && !customerName) {
                errors.customerName = 'Required';
            }
            if (!values.poNumber) {
                errors.poNumber = 'Required';
            }
            if (!values.orderDate) {
                errors.orderDate = 'Required';
            }
            if (!values.formAddedBy) {
                errors.formAddedBy = 'Required';
            }
            // if (!values.email) {
            //     errors.email = 'Required';
            // }
            // if (!values.phoneNumber) {
            //     errors.phoneNumber = 'Required';
            // }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addOrderReceivedDetailsSubmit() },
    });

    const cancelOrderReceivedForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            remarks: ''
        },
        validate: (values: any) => {
            const errors: {
                remarks?: string;

            } = {};
            if (!values.remarks) {
                errors.remarks = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { cancelOrderReceivedDetailsSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const [isOpenListCard, setIsOpenListCard] = useState(false)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [orderReceivedDetailsData, setOrderReceivedDetailsData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(orderReceivedDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [customerName, setCustomerName] = useState<any>([])
    const [count, setCount] = useState<any>(2)
    const [inputList, setInputList] = useState<any>([])
    const [orderQuantityDetails, setOrderQuantityDetails] = useState<any>([])
    const [orderQuantityDetailsData, setOrderQuantityDetailsData] = useState<any>([])
    const [customerDetailsData, setCustomerDetailsData] = useState([])
    const [orderReceivedDetailsId, setOrderReceivedDetailsId] = useState<any>('')
    const [orderReceivedFormOffcanvas, setOrderReceivedFormOffcanvas] = useState<any>(false)
    const [isOpenModal, setIsOpenModal] = useState<any>(false)
    const [isOpenEditModal, setIsOpenEditModal] = useState<any>(false)
    const [isVisibleOnCustomerName, setIsVisibleOnCustomerName] = useState(false)
    const [isCancelOpen, setIsCancelOpen] = useState(false)

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.customerName !== null && i.customerName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.poNumber !== null && i.poNumber.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.orderDate !== null && i.orderDate.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.phoneNumber !== null && i.phoneNumber.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.formAddedBy !== null && i.formAddedBy.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.email !== null && i.email.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function selectCustomerName(e: any) {
        setCustomerName(e)
    }

    function add() {
        for (let i = 0; i < count; i++) {
            setCount(count + 1)
        }
        setInputList([...inputList, { displayOrder: count, typeOfRoll: '', orderItemDescription: '', orderQty: '', orderReceivedQuantityDetailsId: 0 }])
    }

    function handleremove(displayOrder: any, index: any) {
        const list = [...inputList];
        list.splice(index, 1)
        setInputList(list)

        let selectedItem
        orderQuantityDetails.forEach((item: any) => {
            if (item['displayOrder'] == displayOrder) {
                selectedItem = item
                orderQuantityDetails.splice(orderQuantityDetails.indexOf(selectedItem), 1);
            }
        });

        let existingRoll = inputList.filter((data: any) => data.displayOrder == displayOrder)
        setIsDupliteEntry(existingRoll)

        let isCheck: any = list.filter((item: any) => item.typeOfRoll == '' || item.orderItemDescription == '' || item.orderQty == '')
        setIsCheck(isCheck)
    }

    const [isDupliteEntry, setIsDupliteEntry] = useState<any>([])
    const [isCheck, setIsCheck] = useState<any>([])

    function handleInputChange(typeOfRoll: any, orderItemDescription: any, orderQty: any, index: any) {

        const list = [...inputList];

        let existingRoll = inputList.filter((data: any) => data.typeOfRoll == typeOfRoll && data.orderItemDescription == orderItemDescription && data.displayOrder != list[index]['displayOrder'])

        setIsDupliteEntry(existingRoll)

        list[index]['typeOfRoll'] = typeOfRoll
        list[index]['orderItemDescription'] = orderItemDescription
        list[index]['orderQty'] = orderQty;
        list[index]['existingRoll'] = existingRoll != '' ? true : false
        setInputList(list);
        setOrderQuantityDetails(list);

        let isCheck: any = list.filter((item: any) => item.typeOfRoll == '' || item.orderItemDescription == '' || item.orderQty == '')
        setIsCheck(isCheck)
    }

    function openOffCanvas() {
        setOrderReceivedFormOffcanvas(true)
        setInputList([...inputList, { displayOrder: 1, typeOfRoll: '', orderItemDescription: '', orderQty: '', orderReceivedQuantityDetailsId: 0 }])
    }

    function viewQuantityDetails(i: any) {
        setIsOpenModal(true)
        let data = i.orderQuantityDetails;
        for (let i = 0; i < data.length; i++) {
            data[i].sno = i + 1;
        }
        setOrderQuantityDetailsData(data)
    }

    function cancelOrder(i: any) {
        if (i != undefined) {
            let selectedItem = i
            setIsCancelOpen(true)
            setOrderReceivedDetailsId(selectedItem.orderReceivedDetailsId)
        }
    }

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedQuantity = i
            setIsOpenEditModal(true)
            setOrderReceivedDetailsId(selectedQuantity.orderReceivedDetailsId)
            for (let i = 0; i < selectedQuantity.orderQuantityDetails.length; i++) {

                let details = selectedQuantity.orderQuantityDetails[i]
                inputList.push({ displayOrder: i + 1, typeOfRoll: details.typeOfRoll, orderItemDescription: details.orderItemDescription, orderQty: details.orderQty, orderReceivedQuantityDetailsId: details.orderReceivedQuantityDetailsId })
                orderQuantityDetails.push({
                    displayOrder: i + 1, typeOfRoll: details.typeOfRoll, orderItemDescription: details.orderItemDescription, orderQty: details.orderQty,
                    orderReceivedQuantityDetailsId: details.orderReceivedQuantityDetailsId
                })
            }
        }
    }

    function setOrderReceivedDetails() {
        return ({
            licenseKey: getLicenseKey,
            customerName: isVisibleOnCustomerName ? customerName?.value : customerName,
            poNumber: orderReceivedForm.values.poNumber,
            orderDate: orderReceivedForm.values.orderDate,
            email: orderReceivedForm.values.email != '' ? orderReceivedForm.values.email : null,
            phoneNumber: orderReceivedForm.values.phoneNumber != '' ? orderReceivedForm.values.phoneNumber : null,
            formAddedBy: orderReceivedForm.values.formAddedBy,
            orderQuantityDetails: orderQuantityDetails,
            userAccountId: userAccountId,
        })
    }

    function setOrderReceivedQuantityDetails() {
        return ({
            licenseKey: getLicenseKey,
            orderReceivedDetailsId: orderReceivedDetailsId,
            orderQuantityDetails: orderQuantityDetails,
            userAccountId: userAccountId,
        })
    }

    function setCancelOrderReceivedDetails() {
        return ({
            licenseKey: getLicenseKey,
            orderReceivedDetailsId: orderReceivedDetailsId,
            remarks: cancelOrderReceivedForm.values.remarks,
            userAccountId: userAccountId,
        })
    }

    function addOrderReceivedDetailsSubmit() {
        if (orderQuantityDetails.length > 0) {
            showLoader(true)
            let orderReceivedDetailsPostData = setOrderReceivedDetails()
            if (orderReceivedForm.isValid) {
                addOrderReceivedDetails(orderReceivedDetailsPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            closeAndReset()
                        }
                        else if (data.success == false) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false)
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);

                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (orderReceivedForm.isValid == false) {
                toasts("Please fill all details", "Error");
            }
        }
        else {
            toasts("Please enter description details", "Error");
        }
    }

    function updateOrderReceivedQuantityDetailsSubmit() {
        if (orderQuantityDetails.length > 0) {
            showLoader(true)
            let orderReceivedDetailsPostData = setOrderReceivedQuantityDetails()
            updateOrderReceivedQuantityDetails(orderReceivedDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (orderQuantityDetails.length == 0) {
            toasts("Please fill all details", "Error");
        }
    }

    function cancelOrderReceivedDetailsSubmit() {
        showLoader(true)
        let orderReceivedDetailsPostData = setCancelOrderReceivedDetails()
        if (orderReceivedDetailsId > 0) {
            cancelOrderReceivedDetails(orderReceivedDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);

                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (orderReceivedForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function getOrderReceivedDetailsList() {
        getOrderReceivedDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.orderReceivedDetails;
                    if (data != undefined) {
                        setOrderReceivedDetailsData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCustomerDetailsList() {
        getSubModuleType(3, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subModuleType;
                    if (data != undefined) {
                        setCustomerDetailsData(data)
                        setIsVisibleOnCustomerName(true)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setIsVisibleOnCustomerName(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        setOrderReceivedFormOffcanvas(false)
        getOrderReceivedDetailsList()
        setCustomerName('')
        setIsOpenModal(false)
        setIsOpenEditModal(false)
        orderReceivedForm.resetForm()
        cancelOrderReceivedForm.resetForm()
        setInputList([])
        setOrderQuantityDetails([])
        setOrderQuantityDetailsData([])
        setIsCheck([])
        setIsDupliteEntry([])
        setIsCancelOpen(false)
    }

    console.log(orderReceivedForm.values.orderDate);
    

    return (
        <PageWrapper title={`Order Received Form`}>
            <Page>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-7">
                            <CardTitle tag='div' className='h5'>Order Received Form List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Button color='primary' icon='Add' isLight onClick={openOffCanvas}>
                                Add
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody isScrollable>
                        <table className='table table-modern table-hover table-responsive text-nowarp'>
                            <thead>
                                <tr>
                                    <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('customerName')} className='cursor-pointer text-decoration-underline'>Customer Name <Icon size='lg' className={getClassNamesFor('customerName')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('poNumber')} className='cursor-pointer text-decoration-underline'>PO Number <Icon size='lg' className={getClassNamesFor('poNumber')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('email')} className='cursor-pointer text-decoration-underline'>Email <Icon size='lg' className={getClassNamesFor('email')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('phoneNumber')} className='cursor-pointer text-decoration-underline'>Phone Number <Icon size='lg' className={getClassNamesFor('phoneNumber')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('formAddedBy')} className='cursor-pointer text-decoration-underline'>Added By <Icon size='lg' className={getClassNamesFor('formAddedBy')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('orderDate')} className='cursor-pointer text-decoration-underline'>Order Date <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                    <th><div style={{ marginLeft: '30px' }}>Action</div></th>
                                </tr>
                            </thead>
                            {orderReceivedDetailsData != "" ? <>{filteredData.map((item: any) => (<tbody key={item.orderReceivedDetailsId}><tr><td>{item.sno}</td><td>{item.customerName}</td><td>{item.poNumber}</td><td>{item.email != null ? item.email : 'NIL'}</td><td>{item.phoneNumber != null ? item.phoneNumber : 'NIL'}</td><td >{item.formAddedBy != '' ? item.formAddedBy : 'NIL'}</td><td>{item.orderDate}</td><td className="d-print-none"><Popovers desc='Edit' trigger='hover'><Button color='primary' isLight icon='Edit' onClick={() => onActivate(item)}></Button></Popovers> <Popovers desc='Cancel' trigger='hover'><Button color='danger' isLight icon='Cancel' onClick={() => cancelOrder(item)}></Button></Popovers> <Popovers desc='View' trigger='hover'><Button color='primary' isLight icon='RemoveRedEye' onClick={() => viewQuantityDetails(item)}></Button></Popovers></td></tr></tbody>))}</> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}</table>
                    </CardBody>
                    <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>

                <Modal
                    setIsOpen={closeAndReset}
                    isOpen={isOpenModal}
                    titleId='view'
                    isStaticBackdrop
                    isCentered
                    size='lg'>
                    <ModalHeader setIsOpen={closeAndReset}>
                        <ModalTitle id='examplemailcontent'>
                            <div className="h4">Order Quantity Details
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <table className='table table-modern table-hover'>
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th>Type Of Roll</th>
                                    <th>Order Item Description</th>
                                    <th>Quantity</th>
                                    <th>Pending Quantity</th>
                                </tr>
                            </thead>
                            {orderQuantityDetailsData.map((item: any) => (<tbody key={item.orderReceivedQuantityDetailsId}><tr><td>{item.sno}</td><td>{item.typeOfRollName}</td><td>{item.orderItemDescription}</td><td>{item.orderQty}</td><td><span className={item.pendingOrderQty > 0 ? 'text-danger' : 'text-success'}>{item.pendingOrderQty > 0 ? item.pendingOrderQty : <Icon icon='Check' size={'lg'} />}</span></td></tr></tbody>))}
                        </table>

                    </ModalBody>
                </Modal>

                <Modal
                    setIsOpen={closeAndReset}
                    isOpen={isOpenEditModal}
                    titleId='edit'
                    isStaticBackdrop
                    isCentered
                    size='lg'>
                    <ModalHeader setIsOpen={closeAndReset}>
                        <ModalTitle id='examplemailcontent'>
                            <div className="h4">Update Order Quantity Details
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className='col-lg-12'>
                            <FormGroup className='d-flex justify-content-end'>
                                <FormGroup>
                                    <Button onClick={add} icon='Add' color='info' size={'sm'} className='w-100'>Add Description</Button>
                                </FormGroup>
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <table className='table table-bordered table-hover mt-2'>
                                <thead>
                                    <tr className='table-primary text-center'>
                                        <th style={{ width: '30%' }}>TYPE OF ROLL</th>
                                        <th style={{ width: '45%' }}>DESCRIPTION</th>
                                        <th style={{ width: '15%' }}>QTY</th>
                                        {inputList.length > 1 ?
                                            <th style={{ width: '3%' }}></th> : null
                                        }
                                    </tr>
                                </thead>
                                {inputList.map((x: any, i: any) => (
                                    <tbody key={x.orderReceivedQuantityDetailsId}>
                                        <tr>
                                            <td>
                                                <FormGroup id='typeOfRoll' label='Type of Roll' isFloating>
                                                    <Select
                                                        ariaLabel='Type of Roll'
                                                        placeholder="Type of Roll"
                                                        onChange={(e: any) => handleInputChange(e.target.value, x.orderItemDescription, x.orderQty, i)}
                                                        value={x.typeOfRoll}
                                                        isValid={orderReceivedForm.isValid}
                                                        onBlur={orderReceivedForm.handleBlur}
                                                        list={[{ value: 1, label: 'Barcode Sticker' }, { value: 2, label: 'Ribbon Roll Stock' }]}
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup id='orderItemDescription' label='DESCRIPTION' isFloating>
                                                    <Input onChange={(e: any) => handleInputChange(x.typeOfRoll, e.target.value, x.orderQty, i)}
                                                        value={x.orderItemDescription}
                                                        isValid={orderReceivedForm.isValid}
                                                        onBlur={orderReceivedForm.handleBlur}
                                                        placeholder="Enter description" />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup id='orderQty' label='QTY' isFloating>
                                                    <Input onChange={(e: any) => handleInputChange(x.typeOfRoll, x.orderItemDescription, e.target.value, i)}
                                                        value={x.orderQty}
                                                        isValid={orderReceivedForm.isValid}
                                                        onBlur={orderReceivedForm.handleBlur}
                                                        type='number'
                                                        placeholder="Enter Qty" />
                                                </FormGroup>
                                            </td>
                                            {inputList.length > 1 ?
                                                <td>
                                                    <Button className='mt-2' color='danger' isLight icon='Close'
                                                        onClick={() => handleremove(x.displayOrder, i)}
                                                    />
                                                </td> : null
                                            }
                                        </tr>
                                        {x.existingRoll ?
                                            <tr className='text-center'><td className='text-danger' colSpan={4}>Duplite Entry</td></tr> : null
                                        }
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='info'
                            isOutline
                            className='border-0'
                            onClick={closeAndReset}>
                            Close
                        </Button>
                        <Button color='info' type="submit" icon="Save" onClick={updateOrderReceivedQuantityDetailsSubmit} isDisable={isCheck != '' || isDupliteEntry != "" || orderQuantityDetails.length == 0 ? true : false}>
                            Update
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    setIsOpen={closeAndReset}
                    isOpen={orderReceivedFormOffcanvas}
                    titleId='edit'
                    isStaticBackdrop
                    isCentered
                    size='lg'>
                    <ModalHeader setIsOpen={closeAndReset}>
                        <ModalTitle id='orderReceivedEditForm'>
                            <div className="h4">Order Received Form
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row g-4'>
                            <div className='col-6'>
                                <InputGroup>
                                    {isVisibleOnCustomerName ?
                                        <FormGroup id='customerName' label='Customer Name' isFloating>
                                            <SearchableSelect
                                                ariaLabel={''}
                                                isFloating
                                                placeholder='Customer Name'
                                                className="form-control"
                                                value={customerName}
                                                onBlur={orderReceivedForm.handleBlur}
                                                isTouched={orderReceivedForm.touched.customerName}
                                                invalidFeedback={orderReceivedForm.errors.customerName}
                                                list={customerDetailsData.map((data: any) => (
                                                    { value: data.subModuleType, label: data.subModuleType }
                                                ))}
                                                onChange={(e: any) => selectCustomerName(e)} />
                                        </FormGroup> :
                                        <FormGroup id='customerName' label='Customer Name' isFloating>
                                            <Input onChange={(e: any) => selectCustomerName(e.target.value)}
                                                value={customerName}
                                                isValid={orderReceivedForm.isValid}
                                                onBlur={orderReceivedForm.handleBlur}
                                                isTouched={orderReceivedForm.touched.customerName}
                                                invalidFeedback={orderReceivedForm.errors.customerName}
                                                placeholder="Enter Customer Name" />
                                        </FormGroup>
                                    }
                                    <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={() => { setIsVisibleOnCustomerName(!isVisibleOnCustomerName); setCustomerName('') }}></Button>
                                </InputGroup>
                            </div>
                            <div className='col-6'>
                                <FormGroup id='poNumber' label='PO Number' isFloating>
                                    <Input onChange={orderReceivedForm.handleChange}
                                        value={orderReceivedForm.values.poNumber}
                                        isValid={orderReceivedForm.isValid}
                                        onBlur={orderReceivedForm.handleBlur}
                                        isTouched={orderReceivedForm.touched.poNumber}
                                        invalidFeedback={orderReceivedForm.errors.poNumber}
                                        placeholder="Enter PO Numbe" />
                                </FormGroup>
                            </div>
                            <div className='col-6'>
                                <FormGroup id='email' label='Email' isFloating>
                                    <Input onChange={orderReceivedForm.handleChange}
                                        value={orderReceivedForm.values.email}
                                        isValid={orderReceivedForm.isValid}
                                        onBlur={orderReceivedForm.handleBlur}
                                        isTouched={orderReceivedForm.touched.email}
                                        invalidFeedback={orderReceivedForm.errors.email}
                                        placeholder="Enter Email" />
                                </FormGroup>
                            </div>
                            <div className='col-6'>
                                <FormGroup id='phoneNumber' label='Phone Number' isFloating>
                                    <Input onChange={orderReceivedForm.handleChange}
                                        value={orderReceivedForm.values.phoneNumber}
                                        isValid={orderReceivedForm.isValid}
                                        onBlur={orderReceivedForm.handleBlur}
                                        isTouched={orderReceivedForm.touched.phoneNumber}
                                        invalidFeedback={orderReceivedForm.errors.phoneNumber}
                                        placeholder="Enter Phone Number" />
                                </FormGroup>
                            </div>
                            <div className='col-6'>
                                <FormGroup id='formAddedBy' label='Order Entered By' isFloating>
                                    <Input onChange={orderReceivedForm.handleChange}
                                        value={orderReceivedForm.values.formAddedBy}
                                        isValid={orderReceivedForm.isValid}
                                        onBlur={orderReceivedForm.handleBlur}
                                        isTouched={orderReceivedForm.touched.formAddedBy}
                                        invalidFeedback={orderReceivedForm.errors.formAddedBy}  
                                        placeholder="Enter Order Entered By" />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='orderDate' label='Order Date' isFloating>
                                    <Input
                                        onChange={orderReceivedForm.handleChange}
                                        onBlur={orderReceivedForm.handleBlur}
                                        value={orderReceivedForm.values.orderDate}
                                        isValid={orderReceivedForm.isValid}
                                        isTouched={orderReceivedForm.touched.orderDate}
                                        invalidFeedback={orderReceivedForm.errors.orderDate}
                                        type='date'
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-lg-3'>
                                <FormGroup className='d-flex justify-content-end mt-3'>
                                    <FormGroup>
                                        <Button onClick={add} icon='Add' color='info' size={'sm'} className='w-100'>Add Description</Button>
                                    </FormGroup>
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <tr className='table-primary text-center'>
                                            <th style={{ width: '25%' }}>TYPE OF ROLL</th>
                                            <th style={{ width: '45%' }}>DESCRIPTION</th>
                                            <th style={{ width: '15%' }}>QTY</th>
                                            {inputList.length > 1 ?
                                                <th style={{ width: '3%' }}></th> : null
                                            }
                                        </tr>
                                    </thead>
                                    {inputList.map((x: any, i: any) => (
                                        <tbody key={x.displayOrder}>
                                            <tr>
                                                <td>
                                                    <FormGroup id='typeOfRoll' label='Type of Roll' isFloating>
                                                        <Select
                                                            ariaLabel='Type of Roll'
                                                            placeholder="Type of Roll"
                                                            onChange={(e: any) => handleInputChange(e.target.value, x.orderItemDescription, x.orderQty, i)}
                                                            value={x.typeOfRoll}
                                                            isValid={orderReceivedForm.isValid}
                                                            onBlur={orderReceivedForm.handleBlur}
                                                            list={[{ value: 1, label: 'Barcode Sticker' }, { value: 2, label: 'Ribbon Roll Stock' }]}
                                                        />
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <FormGroup id='orderItemDescription' label='DESCRIPTION' isFloating>
                                                        <Input onChange={(e: any) => handleInputChange(x.typeOfRoll, e.target.value, x.orderQty, i)}
                                                            value={x.orderItemDescription}
                                                            isValid={orderReceivedForm.isValid}
                                                            onBlur={orderReceivedForm.handleBlur}
                                                            placeholder="Enter description" />
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <FormGroup id='orderQty' label='QTY' isFloating>
                                                        <Input onChange={(e: any) => handleInputChange(x.typeOfRoll, x.orderItemDescription, e.target.value, i)}
                                                            value={x.orderQty}
                                                            isValid={orderReceivedForm.isValid}
                                                            onBlur={orderReceivedForm.handleBlur}
                                                            type='number'
                                                            placeholder="Enter Qty" />
                                                    </FormGroup>
                                                </td>
                                                {inputList.length > 1 ?
                                                    <td>
                                                        <Button className='mt-2' color='danger' isLight icon='Close'
                                                            onClick={() => handleremove(x.displayOrder, i)}
                                                        />
                                                    </td> : null
                                                }
                                            </tr>
                                            {x.existingRoll ?
                                                <tr className='text-center'><td className='text-danger' colSpan={4}>Duplite Entry</td></tr> : null
                                            }
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='info'
                            isOutline
                            className='border-0'
                            onClick={closeAndReset}>
                            Close
                        </Button>
                        <Button color='info' type="submit" icon="Save" onClick={orderReceivedForm.handleSubmit} isDisable={isCheck != '' || isDupliteEntry != "" || orderQuantityDetails.length == 0 ? true : false}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    setIsOpen={closeAndReset}
                    isOpen={isCancelOpen}
                    titleId='edit'
                    isStaticBackdrop
                    size='sm'>
                    <ModalHeader>{''}</ModalHeader>
                    <ModalBody>
                        <div className="row g-3">
                            <div className="col-12 d-flex justify-content-center">
                                <h5><strong>Are you sure want to cancel ?</strong></h5>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='remarks' label=''>
                                    <Input onChange={cancelOrderReceivedForm.handleChange}
                                        value={cancelOrderReceivedForm.values.remarks}
                                        isValid={cancelOrderReceivedForm.isValid}
                                        onBlur={cancelOrderReceivedForm.handleBlur}
                                        isTouched={cancelOrderReceivedForm.touched.remarks}
                                        invalidFeedback={cancelOrderReceivedForm.errors.remarks}
                                        placeholder="Enter Remarks" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <div className='d-flex gap-2 justify-content-end'>
                                    <Button color='danger' size={'sm'} onClick={cancelOrderReceivedForm.handleSubmit}>
                                        Yes
                                    </Button>

                                    <Button color='info' size={'sm'} onClick={() => setIsCancelOpen(!isCancelOpen)}>
                                        No
                                    </Button>
                                </div>
                            </div>
                            <div className='col-10'></div>
                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default OrderReceivedForm